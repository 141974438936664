.footer {
  width: 100%;
  min-height: 60px;
  background-color: white;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  justify-content: space-between;
  z-index: 10;

  @media (max-width: 1199px) {
    max-height: 26px;
    min-height: 26px;
  }
}

.footerItem {
  color: black;
  font-size: 14px;
  font-family: "Mustica Pro", sans-serif;
  margin: auto;
  flex: 1;

  @media (max-width: 1199px) {
    font-size: 7px;
  }
}

.footerItemButton {
  color: black;
  font-size: 14px;
  font-family: "Mustica Pro", sans-serif;
  margin: auto;
  flex: 1;

  @media (max-width: 1199px) {
    display: none;
  }
}

.footerButton {
  text-transform: none;
  padding: 0;
  letter-spacing: 0;
  flex: 0.66;
  color: black;
  font-size: 14px;
  font-family: "Mustica Pro", sans-serif;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.footerButtonSelected {
  font-weight: bold;
  @media (max-width: 1199px) {
    display: none;
  }
}
