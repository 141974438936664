.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
}

.pageContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  gap: 30px;
}

.highHalf {
  flex: 1.8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.lowHalf {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftDiv {
  justify-content: flex-end;
}

.rightDiv {
  justify-content: flex-start;
}

.image {
  margin-top: 20%;
}

.textContent {
  font-size: 12px;
  font-family: "Mustica Pro", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 15%;
  gap: 20px;
}

.blueText {
  color: #17208a;
  font-weight: bold;
}

.brownText {
  color: #d4a56f;
}

.button {
  min-width: 219px;
  max-width: 219px;
  min-height: 39px;
  :hover {
    cursor: pointer;
  }
}
