.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
}

.box {
  padding: 10px;
  background-color: white;
  cursor: pointer;
  max-width: 185px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: "Mustica Pro";
  font-weight: light;
  margin: auto;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 20px 0;
  }
}

.door {
  position: absolute;
  left: 40%;
  top: 15%;
  width: 18%;
  height: 70%;
  cursor: pointer;
}
