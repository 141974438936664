.background,
.backgroundZoomed {
  position: fixed;
  width: 100vw;
  top: 60px;
  height: calc(100vh - 120px);
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/assets/tapisthermovide2.svg");
  transition: transform 1s ease;
  z-index: -1;
  display: flex;
  flex-direction: column;
}

.backgroundZoomed {
  transform: scale(1.4);
}

.container {
  position: fixed;
  width: 100vw;
  top: 60px;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image {
  margin-top: 20%;
}
