.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 30px;
}

.highHalf {
  flex: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lowHalf {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  min-width: 494px;
  max-width: 494px;
  margin: auto;
  background-color: white;
  font-size: 14px;
  font-family: "Mustica Pro", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 1199px) {
    min-width: 0;
    max-width: 90%;
    font-size: 10px;
  }
}

.boxContent {
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.button {
  min-width: 219px;
  max-width: 219px;
  min-height: 39px;
  :hover {
    cursor: pointer;
  }
}
