.box {
  margin: auto;
  background-color: white;
  font-family: "Mustica Pro";
  font-size: 0.8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px;
  min-width: 368px;
  min-height: 237px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1199px) {
    min-width: 0;
    max-width: 90%;
    min-height: 140px;
  }
}

.typography {
  font-family: "Mustica Pro";
  font-size: 0.8rem;

  @media (max-width: 1199px) {
    font-size: 0.6rem;
  }
}

.textField {
  margin: 10px 0px;
  resize: vertical;
  font-family: "Mustica Pro";

  fieldSet {
    border: none;
    border-radius: 0%;
    border-bottom: 1px dotted black;
    padding: 5px;
  }

  input {
    padding: 0%;
    font-family: "Mustica Pro";
    font-size: 0.8rem;

    @media (max-width: 1199px) {
      font-size: 0.6rem;
    }
  }
}

.textFieldEmpty {
  margin: 10px 0px;
  resize: vertical;
  font-family: "Mustica Pro";

  fieldSet {
    border: none;
    border-radius: 0%;
    border-bottom: 1px dotted red;
    padding: 5px;
  }

  input {
    padding: 0%;
    font-family: "Mustica Pro";
    font-size: 0.8rem;

    @media (max-width: 1199px) {
      font-size: 0.6rem;
    }
  }
}

.success {
  color: green;
  font-family: "Mustica Pro";
}

.error {
  color: red;
  font-family: "Mustica Pro";
  font-size: 0.8rem;
}

.formButton {
  text-transform: none;
  padding: 0;
  letter-spacing: 0;
  color: black;
  font-family: "Mustica Pro";
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }

  @media (max-width: 1199px) {
    font-size: 0.6rem;
  }
}

.disabled {
  text-transform: none;
  padding: 0;
  letter-spacing: 0;
  color: gray;
  font-family: "Mustica Pro";
  font-size: 0.8rem;

  @media (max-width: 1199px) {
    font-size: 0.6rem;
  }
}

.formLine {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;
  padding: 5px;

  & > div {
    flex: 1;
    margin: 0;
  }
}
