body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** fonts */
@font-face {
  font-family: "Mustica Pro";
  src: url("/src/fonts/MUSTICAPRO-REGULAR.OTF") format("opentype");
}

@font-face {
  font-family: "Mustica Pro";
  font-weight: semi-bold;
  font-style: italic;
  src: url("/src/fonts/MUSTICAPRO-SEMIBOLDITALIC.OTF") format("opentype");
}

@font-face {
  font-family: "Mustica Pro";
  font-weight: light;
  src: url("/src/fonts/MUSTICAPRO-LIGHT.OTF") format("opentype");
}

@font-face {
  font-family: "Mustica Pro";
  font-weight: bold;
  src: url("/src/fonts/MUSTICAPRO-BOLD.OTF") format("opentype");
}

* {
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none; /* Pour Internet Explorer et Edge */
}

*::-webkit-scrollbar {
  display: none;
}
