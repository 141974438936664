.container {
  @media (max-width: 1199px) {
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
}

.year {
  font-family: "Mustica Pro";
  text-align: center;
  font-size: 13px;
  letter-spacing: 2px;

  @media (max-width: 1199px) {
    font-size: 5px;
  }
}

.image {
  width: 100%;
  height: 15px;

  @media (max-width: 1199px) {
    width: auto;
    height: 12px;
  }
}
