.questionBox {
  margin: 20px;
  background-color: white;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 212px;
  max-width: 212px;
  text-align: center;
  font-size: 10px;
  font-family: "Mustica Pro", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questionText {
  margin: 10px;
}

.detailInfos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px 10px 20px;
}

.blueText {
  color: #17208a;
}

.redText {
  color: #da3f5a;
}
