.container {
  position: fixed;
  width: 100vw;
  top: 60px;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
  background-size: cover;
}

.box {
  margin: auto;
  background-color: white;
  font-size: 14px;
  font-family: "Mustica Pro", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: 210px;
  max-width: 210px;
  min-height: 188px;
  max-height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.boxContent {
  margin: auto;
}

.boxHeader {
  margin: auto;
  font-weight: bold;
  font-family: "Mustica Pro", sans-serif;
}
