.box {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  aspect-ratio: 8/3;
  min-width: 950px;
  min-height: 400px;
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.line {
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.wait {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gif {
  position: relative;
  left: 50px;
}

.boule {
  max-width: 193px;
  position: absolute;
  left: 35px;
  top: 50px;
}

.marbre {
  left: 10px;
  max-height: 350px;
}
