.header {
  min-height: 60px;
  width: 100%;
  background-color: white;
  color: #fff;
  text-align: center;
  display: flex;
  justify-self: flex-start;
  justify-content: space-between;

  @media (max-width: 1199px) {
    min-height: 50px;
  }
}

.menuMarge {
  flex: 0.2;
}

.menuItem {
  flex: 0.2;
  color: black;
  font-size: 16px;
  font-family: "Mustica Pro", sans-serif;
  text-transform: none;
  padding: 0;
  letter-spacing: 0;
  margin: auto;
  &:hover {
    cursor: pointer;
    font-family: "Mustica Pro";
    font-weight: semi-bold;
    font-style: italic;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.menuBlank {
  flex: 0.2;
  color: black;
  font-size: 16px;
  font-family: "Mustica Pro", sans-serif;
  text-transform: none;
  padding: 0;
  letter-spacing: 0;
  margin-top: 18px;

  @media (max-width: 1199px) {
    display: none;
  }
}

.menuItemSelected {
  font-weight: bold;
}

.titleItem {
  flex: 1.2;
  color: black;
  font-size: 20px;
  font-family: "Mustica Pro", sans-serif;
  margin: auto;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    font-size: 15px;
    flex: 1.4;
  }
}

.disabled {
  color: grey;
  cursor: none;
}

.mobileItem {
  display: none;
  flex: 0.3;
  margin: auto;

  @media (max-width: 1199px) {
    display: block;
  }
}

.headerMenu {
  padding: 10px;
}
