.page {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/assets/mur_texte.jpg");

  @media (max-width: 1199px) {
    background-position: calc(50% - 100px) center;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: scroll;

  @media (max-width: 1199px) {
    gap: 20%;
  }
}

.left {
  flex: 1.3;

  @media (max-width: 1199px) {
    flex: 1.4;
  }
}

.box {
  max-width: 451px;
  margin: auto;
  background-color: white;
  font-family: "Mustica Pro", sans-serif;

  @media (max-width: 1199px) {
    max-width: 90%;
  }
}

.boxContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right {
  flex: 0.7;

  @media (max-width: 1199px) {
    flex: 0.2;
  }
}

.wayComputer {
  margin-left: 60%;
  margin-bottom: 50%;
  background-color: white;
  font-size: 16px;
  font-family: "Mustica Pro", sans-serif;
  min-width: 118px;
  max-width: 118px;
  min-height: 52px;
  height: 90%;
  display: flex;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.wayMobile {
  display: none;

  @media (max-width: 1199px) {
    display: block;
  }
}

.image {
  max-height: 20px;
  margin: 50px auto 30px auto;

  @media (max-width: 1199px) {
    max-height: 10px;
    margin: 30px auto;
  }
}

.description {
  margin: 0 50px 30px 50px;
  font-family: "Mustica Pro";
  font-weight: "light";
  font-size: 14px;

  @media (max-width: 1199px) {
    margin: 0 20px 20px 20px;
    font-size: 12px;
  }
}

.wayText {
  flex: 0.7;
  margin: 10px 0px 10px 10px;
  font-family: "Mustica Pro";
  font-weight: "light";
  font-size: 14px;
}

.wayImage {
  flex: 0.3;
  margin: 10px 10px 10px 0px;
}
