@keyframes slideLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
    }

    @keyframes slideRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
    }

    @keyframes arriveFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
    }

    @keyframes arriveFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
    }

.image,
.imageSlideRight,
.imageSlideLeft,
.imageSlideFromRight,
.imageSlideFromLeft {
  max-height: 450px;
  max-width: 750px;
  box-shadow: 5px 12px 8px 0px #00000033;

  @media (max-width: 1199px) {
    max-width: 80%;
    margin-bottom: 30%;
  }

  display: flex;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.imageSlideRight {
  animation-name: slideRight;
}
.imageSlideLeft {
  animation-name: slideLeft;
}
.imageSlideFromRight {
  animation-name: arriveFromRight;
}
.imageSlideFromLeft {
  animation-name: arriveFromLeft;
}

.sculpture,
.sculptureSlideRight,
.sculptureSlideLeft,
.sculptureSlideFromRight,
.sculptureSlideFromLeft {
  max-width: 400px;
  margin-top: 225%;

  @media (max-width: 1199px) {
    margin-top: 100%;
    max-width: 70%;
  }

  display: flex;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.sculptureSlideRight {
  animation-name: slideRight;
}
.sculptureSlideLeft {
  animation-name: slideLeft;
}
.sculptureSlideFromRight {
  animation-name: arriveFromRight;
}
.sculptureSlideFromLeft {
  animation-name: arriveFromLeft;
}