@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes arriveFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes arriveFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 1199px) {
    display: none;
  }
}

.containerMobile {
  display: none;
  position: relative;

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    max-height: calc(100vh - 76px);
    overflow: hidden;
  }

  & > div {
    display: flex;
    flex-direction: row;
    position: absolute;
    text-align: center;
    bottom: 0%;
  }
}

.artworkSide {
  width: 100%;
  height: 100%;
  position: relative;
}

.artwork {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 120px);
}

.arrow {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
  min-width: 65px;

  @media (max-width: 1199px) {
    padding: 10px;
    min-width: 40px;
  }
}

.image,
.imageSlideRight,
.imageSlideLeft,
.imageSlideFromRight,
.imageSlideFromLeft {
  max-height: 450px;
  max-width: 750px;
  box-shadow: 5px 12px 8px 0px #00000033;

  @media (max-width: 1199px) {
    max-width: 80%;
    margin-bottom: 30%;
  }

  display: flex;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.imageSlideRight {
  animation-name: slideRight;
}
.imageSlideLeft {
  animation-name: slideLeft;
}
.imageSlideFromRight {
  animation-name: arriveFromRight;
}
.imageSlideFromLeft {
  animation-name: arriveFromLeft;
}

.sculpture,
.sculptureSlideRight,
.sculptureSlideLeft,
.sculptureSlideFromRight,
.sculptureSlideFromLeft {
  max-width: 400px;
  margin-top: 225%;

  @media (max-width: 1199px) {
    margin-top: 100%;
    max-width: 70%;
  }

  display: flex;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.sculptureSlideRight {
  animation-name: slideRight;
}
.sculptureSlideLeft {
  animation-name: slideLeft;
}
.sculptureSlideFromRight {
  animation-name: arriveFromRight;
}
.sculptureSlideFromLeft {
  animation-name: arriveFromLeft;
}

.content,
.contentSlideRight,
.contentSlideLeft,
.contentSlideFromRight,
.contentSlideFromLeft {
  display: flex;
  width: 100%;
  animation-duration: 0.35s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.contentSlideRight {
  animation-name: slideRight;
}
.contentSlideLeft {
  animation-name: slideLeft;
}
.contentSlideFromRight {
  animation-name: arriveFromRight;
}
.contentSlideFromLeft {
  animation-name: arriveFromLeft;
}
