.container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/assets/Sol_table.jpg");

  @media (max-width: 1199px) {
    display: none;
  }
}

.containerMobile {
  display: none;

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-image: url("../../../public/assets/Sol_table_reversed.jpg");
    flex-grow: 1;
    width: 100%;
    background-size: 120%;
  }
}

.box {
  margin: auto;
  font-size: 15px;
  font-family: "Mustica Pro";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  aspect-ratio: 6/3;

  @media (max-width: 1199px) {
    height: 70%;
    aspect-ratio: 3/6;
  }
}

.firstLine {
  flex: 1;
  display: flex;
  padding-right: 15%;
  gap: 10%;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }

  & > div {
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.secondLine {
  flex: 1;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }

  & > div {
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.thirdLine {
  flex: 1;
  display: flex;
  padding-right: 10%;
  gap: 10%;
  align-items: center;
  justify-content: center;
  text-align: center;

  :hover {
    cursor: pointer;
  }

  & > div {
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.title {
  font-family: "Mustica Pro";
  font-weight: bold;
  font-size: 18px;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 11px;
  }
}

.description {
  font-family: "Mustica Pro";
  text-align: center;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: semi-bold;

  @media (max-width: 1199px) {
    font-size: 7px;
  }
}

.worksMenuComputer {
  display: block;

  @media (max-width: 1199px) {
    display: none;
  }
}

.notStartedWork {
  @media (max-width: 1199px) {
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
  }
}
