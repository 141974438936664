.box {
  background-color: white;
  font-size: 12px;
  font-family: "Mustica Pro", sans-serif;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10%;
  left: 10%;
  padding: 10px 0px;
  box-shadow: 0px 4px 5px 0px #00000014;

  @media (max-width: 1199px) {
    font-size: 10px;
    position: initial;
    padding: 10px 10px;
    margin: 50px;
  }
}

.boxContent {
  margin: auto 10px;

  @media (max-width: 1199px) {
    margin: auto;
  }
}
